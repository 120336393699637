import { ActionType } from "typesafe-actions";
import {
  addProduct,
  createProduct,
  deleteProduct,
  loadProducers,
  loadProducersSuccess,
  loadWarehouseSuccess,
  loadWarehouses,
  setPagination,
  setPaginationError,
  setPaginationSuccess,
  updateProduct,
} from ".";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  documentApiInstance,
  producerApiInstance,
  productApiInstance,
  warehouseApiInstance,
} from "services";
import { selectPagination } from "./selectors";

export function* setPaginationSaga(action: ActionType<typeof setPagination>) {
  const { pagination } = action.payload;
  try {
    const pageSize = pagination.pageSize;
    const pageNumber = pagination.pageNumber;
    const warehouseId = pagination.warehouseId;
    const producerId = pagination.producerId;
    const isActive = pagination.isActive;
    const hasStock = pagination.hasStock;
    if (warehouseId && warehouseId !== "Toate") {
      const response = yield call(
        [warehouseApiInstance, warehouseApiInstance.getProductsByWarehouse],
        warehouseId,
        pageSize,
        pageNumber,
        isActive,
        hasStock
      );
      const totalPagesCount = response.data.totalPagesCount;
      yield put(
        setPaginationSuccess({
          productsList: response.data.productDaoList,
          totalPagesCount,
        })
      );
    } else if (producerId) {
      const response = yield call(
        [productApiInstance, productApiInstance.getProductsForProducer],
        producerId,
        pageSize,
        pageNumber
      );
      const totalPagesCount = response.data.totalPagesCount;
      yield put(
        setPaginationSuccess({
          productsList: response.data.productDaoList,
          totalPagesCount,
        })
      );
    } else {
      const response = yield call(
        [productApiInstance, productApiInstance.getAllProduct],
        pageSize,
        pageNumber,
        isActive,
        hasStock
      );
      const totalPagesCount = response.data.totalPagesCount;
      yield put(
        setPaginationSuccess({
          productsList: response.data.productDaoList,
          totalPagesCount,
        })
      );
    }
  } catch (e: any) {
    const response = e;
    yield put(
      setPaginationError({
        error: response.message ?? "Something went wrong",
      })
    );
  }
}

export function* loadWarehousesSaga() {
  try {
    const response = yield call([
      warehouseApiInstance,
      warehouseApiInstance.getWarehouseList,
    ]);
    yield put(loadWarehouseSuccess({ warehouseList: response.data }));
  } catch (e: any) {
    const response = e;
    console.log(
      "Something went wrong when loading warehouse filters",
      response.message
    );
  }
}

export function* loadProducersSaga() {
  try {
    const response = yield call([
      producerApiInstance,
      producerApiInstance.getProducerList,
    ]);
    yield put(loadProducersSuccess({ producerList: response.data }));
  } catch (e: any) {
    const response = e;
    console.log(
      "Something went wrong when loading producer filters",
      response.message
    );
  }
}

export function* updateProductSaga(action: ActionType<typeof updateProduct>) {
  const { product, pagination } = action.payload;

  try {
    yield call([productApiInstance, productApiInstance.updateProduct], product);
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
  }
}

export function* deleteProductSaga(action: ActionType<typeof deleteProduct>) {
  const { productId } = action.payload;

  try {
    yield call(
      [productApiInstance, productApiInstance.deleteProductById],
      productId
    );
  } catch (e: any) {
    const response = e;
  }
}

export function* createProductSaga(action: ActionType<typeof createProduct>) {
  const { image, product, pagination } = action.payload;

  try {
    yield call(
      [productApiInstance, productApiInstance.saveProduct],
      image,
      product
    );
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
  }
}

export function* addProductSaga(action: ActionType<typeof addProduct>) {
  const { productId, storeId, quantity } = action.payload;

  try {
    yield call([documentApiInstance, documentApiInstance.addProduct], {
      storeId,
      productId,
      quantity,
    });
    const pagination = yield select(selectPagination);
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
    console.log("Something went wrong when adding product:", response.message);
  }
}

export function* productSaga() {
  yield takeLatest(setPagination, setPaginationSaga);
  yield takeLatest(loadWarehouses, loadWarehousesSaga);
  yield takeLatest(loadProducers.type, loadProducersSaga);
  yield takeLatest(addProduct.type, addProductSaga);
  yield takeLatest(createProduct.type, createProductSaga);
  yield takeLatest(updateProduct.type, updateProductSaga);
  yield takeLatest(deleteProduct.type, deleteProductSaga);
}
