import { ShoppingCart } from "@mui/icons-material";
import { TopBarWrapper } from "./atoms/TopBarWrapper";
import { ListWrapper } from "./atoms/ListWrapper";
import { ListItemWrapper } from "./atoms/ListItemWrapper";
import { TitleWrapper } from "./atoms/TitleWrapper";
import { TopbarProfile } from "./components/TopbarProfile";
import { TopbarAdmin } from "./components/TopbarAdmin";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "containers/App/slice/selectors";
import { IUserRoleEnum } from "openapi/types";
import { RouteVar } from "utils/constants";
import { Text } from "atoms";

export const TopBar = () => {
  const user = useSelector(selectUser);

  return (
    <TopBarWrapper>
      <ListWrapper>
        <TitleWrapper to={"/"}>ALTFELDISTRIB</TitleWrapper>
        {getNavigationByRole(user?.role)}
      </ListWrapper>
      <div className="d-flex justify-content-center align-items-center">
        <Text className="mr-5">Bună, {user?.email}</Text>
        {user?.role === IUserRoleEnum.Store && (
          <Link
            to={"/cart"}
            style={{ backgroundColor: "transparent", color: "black" }}
          >
            <ShoppingCart className="mt-1" />
          </Link>
        )}
        <TopbarProfile />
      </div>
    </TopBarWrapper>
  );
};

const getNavigationByRole = (role?: IUserRoleEnum) => {
  switch (role) {
    case IUserRoleEnum.Store:
      return (
        <>
          <ListItemWrapper className="ml-5" to={"/"}>
            DASHBOARD
          </ListItemWrapper>
          <ListItemWrapper to={RouteVar.productsPage.Route}>
            PRODUSE
          </ListItemWrapper>
          <ListItemWrapper to={RouteVar.ordersPage.Route}>
            COMENZI
          </ListItemWrapper>
        </>
      );
    case IUserRoleEnum.Producer:
      return (
        <>
          <ListItemWrapper className="ml-5" to={"/"}>
            DASHBOARD
          </ListItemWrapper>
          <ListItemWrapper to={RouteVar.productsPage.Route}>
            PRODUSE
          </ListItemWrapper>
          <ListItemWrapper to={RouteVar.ordersPage.Route}>
            RAPORT VANZARI
          </ListItemWrapper>
        </>
      );
    case IUserRoleEnum.Warehouse:
      return (
        <>
          <ListItemWrapper className="ml-5" to={"/"}>
            DASHBOARD
          </ListItemWrapper>
          <ListItemWrapper to={RouteVar.productsPage.Route}>
            PRODUSE
          </ListItemWrapper>
          <ListItemWrapper to={RouteVar.ordersPage.Route}>
            COMENZI
          </ListItemWrapper>
        </>
      );
    case IUserRoleEnum.SysAdmin:
      return (
        <>
          <TopbarAdmin />
          <ListItemWrapper to={RouteVar.productsPage.Route}>
            PRODUSE
          </ListItemWrapper>
          <ListItemWrapper to={RouteVar.ordersPage.Route}>
            COMENZI
          </ListItemWrapper>
        </>
      );
    default:
      return (
        <>
          <ListItemWrapper className="ml-5" to={"/"}>
            DASHBOARD
          </ListItemWrapper>
          <ListItemWrapper to={RouteVar.productsPage.Route}>
            PRODUSE
          </ListItemWrapper>
          <ListItemWrapper to={RouteVar.ordersPage.Route}>
            COMENZI
          </ListItemWrapper>
        </>
      );
  }
};
