import { generatePath } from "react-router-dom";

export const RouteVar = {
  login: {
    Route: "/login",
  },
  mainpage: {
    Route: "/",
  },
  storesPage: {
    Route: "/stores",
  },
  producersPage: {
    Route: "/producers",
  },
  warehousesPage: {
    Route: "/warehouses",
  },
  paymentsPage: {
    Route: "/payments",
  },
  usersPage: {
    Route: "/users",
  },
  productsPage: {
    Route: "/products",
  },
  cartPage: {
    Route: "/cart",
  },
  ordersPage: {
    Route: "/orders",
  },
  singleOrderPage: {
    Route: "/order/:orderId",
    getPath: ({ orderId }: { orderId: string }) => {
      return generatePath(RouteVar.singleOrderPage.Route, { orderId });
    },
  },
  profilePage: {
    Route: "/profile",
  },
};
